import { Button, Typography, Stack, Link, Grid } from "@mui/material"
import ReactJS from "./Assets/react.png"
import Material from "./Assets/mui.png"
import JS from "./Assets/js.png"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from "@mui/system";

const Home = ()=> {
    const imagesList = [ReactJS, Material, JS]
    return (
        <>
            <Typography variant="h1" sx={{
                fontSize : {xs: "3em", sm: "4em", md: "4em", lg: "6em"}
            }}>
                Petr Stibor
            </Typography>

            
            <Typography variant="h2" sx={{
                fontSize : {xs: "1.5em", sm: "2em", md: "3em", lg: "4em"}
            }}>
                <ArrowBackIosIcon 
                    fontSize="large" 
                    sx={{
                        color: '#f0bf0f'
                    }}
                />
                    Junior React JS Developer

                    <ArrowForwardIosIcon 
                    fontSize="large" 
                    sx={{
                        color: '#f0bf0f'
                    }}
                />   
                
            </Typography>
           

            <Box sx={{
                maxWidth: {lg: "750px"},
                marginTop: {xs: "40px", sm: "40px", lg: "40px"}
            }}>
                <Typography variant="h6">
                    I created this simple aplication to demonstrate my skills in React JS library. <br/><br/>
                    So far I created just few projects, but from my point of view, what better way to show I understand React than make something in React, right?
                </Typography>

               
            </Box>


            <Grid container spacing={2} marginTop="40px">

                <Grid item sm={12} lg={5}>
                    <Typography variant="h3" sx={{
                        fontSize: {xs: "2.5em", lg: "3.5em"},
                        marginTop: {lg: "50px"},
                        textAlign: {xs: "left", sm: "center", md: "center", lg: "left"}
                    }}>
                        My stack so far
                    </Typography>
                </Grid>

                
                <Grid item xs={12 }md={12} lg={7}>
                    <Stack
                        direction={{xs: "column", sm: "row", md: "row"}}
                        justifyContent={{xs: "center"}}
                        alignItems={{xs: "center"}}
                    >
                        <Typography color="#f0bf0f" sx={{
                            fontSize: {xs: "3em", sm: "6em", md: "6em", lg: "6em", xl: "6em"}
                        }}>
                            [
                        </Typography>
                            {imagesList.map(image=>{
                                return (
                                    <Box sx={{
                                        m: {xs: 2, sm: 1, md: 2, lg: 4}
                                    }}><img src={image} alt="something"/></Box>
                                )
                            })}
                        <Typography color="#f0bf0f" sx={{
                            fontSize: {xs: "3em", sm: "6em", md: "6em", lg: "6em", xl: "6em"}
                        }}>
                            ]
                        </Typography>
                    </Stack>
                </Grid>
            
              </Grid>


        </>
    )
}

export default Home