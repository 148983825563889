import { Box, Typography, Grid, Stack, Link, Button } from "@mui/material"
import GitHubIcon from '@mui/icons-material/GitHub';
import portfolioData from '../Components/Texts/projects.json'

const MyPortfolio = ()=> {
    return (
        <>
            <Typography variant="h1" sx={{
                fontSize : {xs: "3em", sm: "4em", md: "4em", lg: "6em"}
            }}>
                My Portfolio
            </Typography>

            <Grid container spacing={2} marginTop="40px">

                {portfolioData.map(portfolio=>{

                    const {id, appname, appImage, appDescription, gitLink, gitLinkBackend, liveDemoLink } = portfolio

                    return (                    
                    
                    <Grid key={id} item md={6} marginBottom="40px">

                        <Typography variant="h4">{appname}</Typography>

                        <Typography sx={{
                            marginBottom: "20px"
                        }}>
                            {appDescription}
                        </Typography>

                        <Box>
                            <img src={appImage} alt={appname} />
                        </Box>

                        <Stack direction="row" alignItems="center" spacing={3} marginTop="20px" marginBottom="20px">

                            

                            {gitLink == null ? ("") :
                            (
                            <Link href={gitLink} target="blank">
                                <Button variant="outlined">
                                    <GitHubIcon fontSize="large" sx={{ color: '#f0bf0f'}} />
                                    Github Link
                                </Button>
                            </Link>
                            )
                            }


                            {gitLinkBackend == null ? ("") :
                            (
                            <Link href={gitLinkBackend} target="blank">
                                <Button variant="outlined">
                                    <GitHubIcon fontSize="large" sx={{ color: '#f0bf0f'}} />
                                    Github BackEnd Link
                                </Button>
                            </Link>
                            )
                            }       
                            

                            {liveDemoLink == null ? ("") :
                            (
                            <Link href={liveDemoLink} target="blank">
                                <Button variant="outlined">
                                    <GitHubIcon fontSize="large" sx={{ color: '#f0bf0f'}} />
                                        Live demo
                                </Button>
                            </Link>
                            )
                            }
                            
                            
                        
                        </Stack>

                    </Grid>
                    )
                })}
                

            </Grid>
        </>
    )
}

export default MyPortfolio