import { Stack, Container, Grid, Link, Menu, MenuItem, Button } from "@mui/material"
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { NavLink } from "react-router-dom";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';

// Array with url links

const pages = [
    {
        pageId: 1,
        pageName: "Home",
        pageLink: '/',
    },
    {
        pageId: 2,
        pageName: "About me",
        pageLink: '/aboutme',
    },
    {
        pageId: 3,
        pageName: "My Skills",
        pageLink: '/myskills',
    },
    {
        pageId: 4,
        pageName: "My React JS Portfolio",
        pageLink: '/myportfoilo',
    }
]

const Header = ()=> {

    const [anchorEl, setAchorEl] = useState(null)
    const [hamburgerActive, setHamburgerActive] = useState(false)

    const handleClose = ()=> {
        return (
            setAchorEl(null),
            setHamburgerActive(false)
        )
    }
    const openMenu = (event)=> {
        return (
            setAchorEl(event.currentTarget),
            setHamburgerActive(true)
        )
    }
   


    
    // Style for active link
    const navLinkStyles =({isActive})=> {
        return {
            color: isActive ? '#f0bf0f' : '#f0bf0f',
            textDecoration: isActive ? 'underline' : 'none'
        }
    }

    return (
        <Container>
            <Grid container paddingTop="40px" sx={{
                display: {xs: "none", sm: "none", md: "flex"}
            }}>
                <Grid item md={4} >
                    <Stack direction="row">
                        <Link href="https://github.com/petrStibor?tab=repositories" target="blank"><GitHubIcon fontSize="large" sx={{ color: '#f0bf0f'}} /></Link>
                        <Link href="https://www.linkedin.com/in/petr-stibor-45208a1b8/" target="blank"><LinkedInIcon fontSize="large" sx={{ color: '#f0bf0f'}}/></Link>
                    </Stack>
                </Grid>

                <Grid item md={8}>
                    <Stack 
                        direction="row" 
                        justifyContent="flex-end"
                        spacing={4}
                    >
                    
                    {pages.map(page=>{
                        const {pageId, pageName, pageLink} = page
                        return (
                            <NavLink key={pageId} to={pageLink} style={navLinkStyles}>{pageName}</NavLink>
                        )
                    })}
                    </Stack>
                </Grid>
            </Grid>


            <Grid container sx={{
                    display: {xs: "flex", sm: "flex", md: "none"},
                    paddingTop: "40px"
                }}>

                <Grid item xs={2}>
                    <Stack direction="row">
                        <Link href="https://github.com/petrStibor?tab=repositories" target="blank"><GitHubIcon fontSize="large" sx={{ color: '#f0bf0f'}} /></Link>
                        <Link href="https://www.linkedin.com/in/petr-stibor-45208a1b8/" target="blank"><LinkedInIcon fontSize="large" sx={{ color: '#f0bf0f'}}/></Link>
                    </Stack>
                </Grid>
                
                <Grid item xs={10}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <MenuIcon onClick={openMenu} sx={{
                            color: hamburgerActive ? '#ffff' : '#f0bf0f',
                            fontSize: 30
                        }}>Menu</MenuIcon>

                        <Menu
                            id="menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}

                        >
                            {pages.map(page=>{
                                const {pageId, pageName, pageLink} = page
                                return (
                                    <MenuItem key={pageId} onClick={handleClose}>
                                        <NavLink to={pageLink} style={navLinkStyles}>{pageName}</NavLink>
                                    </MenuItem>
                                )
                            })}
                        </Menu>
                    </Stack>
                </Grid>

            </Grid>

        

        </Container>
    )
}

export default Header