import { Box, Typography, Grid, Stack } from "@mui/material"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import PersonIcon from '@mui/icons-material/Person';
import Ondra from './Assets/ondra.JPG'

const Aboutme = ()=> {
    return (
        <>
           

            <Grid container spacing={2} marginTop= "40px">

                <Typography variant="h1" sx={{
                fontSize : {xs: "3em", sm: "4em", md: "4em", lg: "6em"}
                }}>
                    About Me
                </Typography>

                <Grid item sm={12} lg={8}>

                    <Stack
                    direction= {{xs: "column", sm: "row", md: "row", lg: "row", xl: "row"}}
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    >

                        <PersonIcon fontSize="large" sx={{ color: '#f0bf0f'}}/>
                        <Typography sx={{
                            fontSize: {xs: "1em", sm: "1em", md: "1em", lg: "1em", xl: "1em"}
                        }}>
                            My age: 35++
                        </Typography>

                        <LocationOnIcon fontSize="large" sx={{ color: '#f0bf0f'}}/>
                        <Typography>I am from: Ostrava</Typography>

                        <SentimentSatisfiedAltIcon fontSize="large" sx={{ color: '#f0bf0f'}}/>
                        <Typography>Status: Married (happily)</Typography>

                    </Stack>
                <Box textAlign="justify">   

                    <Typography sx={{marginTop: '20px'}}>
                        As you can tell, right now, my React skills are on Junior level but I am trying to work everyday (really)
                        to get deeper understanding of how React JS works to build better and more complex projects.
                            <br />
                            <br />
                        For last 8-ish years I was working in webdevelopment as a freelancer and I was able to build my own brand people recognize - Webovky pro firmy. 
                        When I started webdevelopment, my goal was to learn how to create websites and make money just to provide for myself.
                        At this point I have returning customers and pretty much stable job stream thanks to reputation I was able to build during those years. 
                            <br />
                            <br />
                        My current goal is to master programming skills and elevate my knowledge on whole new level to be able to build 
                        something more complex rather then simple Wordpress websites.
                            <br />
                            <br />
                        I hope you will give me a chance to learn something from your company if you decide to give me a oportunity.
                    </Typography>
                </Box>
                </Grid>
                
                <Grid item sm={12} lg={4}>
                    <Box>
                        <img src={Ondra} alt="Father and Son" width="100%"/>
                        <Typography sx={{
                            textAlign: "center",
                            fontSize: "0.9em",
                        }}>
                        Me, shamelessly exploiting my son to get a job.
                        </Typography>
                    </Box>
                </Grid>
                
            </Grid>


        </>
 
    )
}

export default Aboutme