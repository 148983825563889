import { ThemeProvider, createTheme } from "@mui/material/styles"
import { CssBaseline } from "@mui/material"
import {Container} from '@mui/material'
import {Routes, Route} from 'react-router-dom'
import Header from "./Components/Header";
import Home from "./Components/Home"
import Aboutme from "./Components/Aboutme";
import MySkills from "./Components/Myskills";
import MyPortfolio from "./Components/Myportfolio";
import Peter from "./Components/Assets/peter.png"

const darkTheme = createTheme({

  
  palette: {
    mode: 'dark',
  info: {
    main: '#f0bf0f',
  },
  primary: {
    main: '#f0bf0f'
  },
  background: {
    default: "#0f1e29",
    }
  },
  typography: {
    h1: {
      fontSize: "7em",
      color: '#f0bf0f',
    },
    h3: {
      color: '#f0bf0f',
      marginTop: "40px"
  
    },
    h4: {
      color: '#f0bf0f',
  
    },
    body1: {
      fontSize: "1.1em",
    },
    h6: {
      fontWeight: "700"
    }
  }

  
  
});


const App = ()=> {
  return (
    <ThemeProvider theme={darkTheme}>
    <CssBaseline/>
      <Container maxWidth="false" text style={{
        backgroundImage: `url(${Peter})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundAttachment: "fixed",
        backgroundSize: "contain",
        marginBottom: "40px"
    }}
    >
        <Header />
          <Container maxWidth="lg" style={{
            marginTop: "40px"
          }}>
              <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/aboutme" element={<Aboutme />}/>
                <Route path="/myskills" element={<MySkills/>}/>
                <Route path="/myportfoilo" element={<MyPortfolio/>}/>
              </Routes>

          </Container>
          
        </Container>
    </ThemeProvider>
  )
}

export default App