import { Box, Typography } from "@mui/material"
import skily from './Texts/skills.json'


const MySkills = ()=> {
    return (
        <Box textAlign="justify">

            <Typography variant="h1" sx={{
                fontSize : {xs: "3em", sm: "4em", md: "4em", lg: "6em"}
            }}>
                My Skills
            </Typography>

            {skily.map(skill=>{
                const {skillId, skillName, skillDescription} = skill
                return (
                    <div key={skillId}>
                        <Typography variant="h3" sx={{
                            fontSize: {xs: "2em", sm: "2em", md: "2em"}
                        }}>
                            {skillName}
                        </Typography>

                        <Typography sx={{
                            whiteSpace: "pre-line"
                        }}>{skillDescription}</Typography>
                    </div>
                )
            })}
             
        </Box>
 
    )
}

export default MySkills